import React from 'react'
import { Button } from '@material-tailwind/react'
import Impdates from '../Utilities/Impdates'
import Notices from '../Utilities/Notices'
import Downloads from '../Utilities/Downloads'
import { Link } from 'react-router-dom';
import { Component } from '../Utilities/Carousel';


const Home = () => {
  return (
    <div className='p-4'>
      <Component/>
      <h1 className='text-3xl text-center font-bold mt-4'>About Conference</h1>
      <hr className="mt-2 border-black border-2 w-96 m-auto"/>
      <p className='text-justify font-semibold mt-4'>ICCRDA-2025 is organised with the objective of bringing together innovative scientists, professors, research scholars, students and industrial experts in the field of Sciences, Computational Methods and Techniques to a common platform. The primary goal of the conference is to promote the exchange of innovative scientific information between researchers, developers, engineers, students, and practitioners. Overall the conference will provide the researchers and attendees with prospects for national and international collaboration and networking among universities and institutions from India and abroad for promoting research. ICCRDA 2025 will be organized by Duy Tan University, Vietnam on 17-18 January, 2025. <br/><br/>The proceedings of ICCRDA 2025 will be published by IEEE  Computer Society (IEEE Xplore) indexed by all reputed databases like Scopus, EI, WoS.</p>
      <Button color='blue' className='mt-4 p-3 '><Link to={'https://cmt3.research.microsoft.com/User/Login?ReturnUrl=%2FICCRDA2025'}>Paper Submission Link</Link></Button>
      <div className='flex flex-col md:flex-row justify-around my-8'>
      <Impdates />
      <Downloads/>
      <Notices/>
      </div>
      
    </div>
  )
}

export default Home