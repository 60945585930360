export const bigDataTopics = [
    "Big Data processing and management",
    "Big Data search and mining",
    "Big Data platforms",
    "Big Data Analytics and Metrics",
    "Security Intelligence with Huge Data",
    "Big Data and social networks",
    "Big Data economics",
    "Foundational models for Big Data",
    "Linked Huge Data",
    "Knowledge Discovery from Huge Data",
    "Business Performance Management using Big Data Technique",
    "Computational Intelligence for Huge Data",
    "Big Data Management and Frameworks"
  ];
  