export const dataAnalyticsTopics = [
    "Architectures for generic sentiment analysis systems",
    "Open API for data analytics, In-database analytics",
    "Relevant / redundant / obsolete data analytics",
    "Simulation applications",
    "Sentiment analysis techniques on social media",
    "Forecast analytics, Financial risk management, ROI analytics",
    "Crawling web services; Cross-database analytics",
    "Multi-modal support for data analytics",
    "Tools, frameworks and mechanisms for data analytics",
    "Nomad analytics, Predictive analytics",
    "Analytics visualization",
    "Scalable data analytics",
    "Pre-built analytics (pattern, time-series, clustering, graph, statistical analysis, etc.)",
    "Aspect-based analysis, Comparative-sentiment analysis",
    "Statistical applications",
    "Mass data analytics Storing, dropping and filtering data",
    "Document-level analysis, Sentence-level analysis",
    "Trust in data analytics",
    "Big data analytics, Deep data analytics"
  ];
  