import React from 'react'
import CardCarousel from '../Utilities/CardCarousel'

const Attractions = () => {
    const cards = [
        { id: 1, website:'http://songthuhotel.vn',phone: '+84 - 511 - 3827666', address: '30 - 34 Tran Phu, Hai Chau District, Da Nang', image: '/images/hotels/img1.jpg' },
        { id: 2, website:'http://danangpetrohotel.com.vn/',phone: '+84 - 511- 3834834', address: '07 Quang Trung, Hai Chau District, Da Nang', image: '/images/hotels/img2.jpg' },
        { id: 3, website:'http://www.novotel-danang-premier.com/',phone: '+84 (0)511 392 9999', address: '36 Bach Dang, Hai Chau District, Danang', image: '/images/hotels/img3.jpg' },
      ];
  return (
    <div className='w-full m-auto'>
    <h1 className='text-4xl font-semibold text-center m-3'>Accommodation & Attractions</h1>
    <hr className="mb-4 border-black border-2 w-96 m-auto"/>    
    <CardCarousel/>
    <p className='text-3xl m-4 text-center font-medium text-blue-700'>Hotels in Da Nang</p><hr/>
    <div className="flex flex-wrap m-4">
        {cards.map((card) => (
          <div key={card.id} className="p-4 md:w-1/2 lg:w-1/3">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden h-auto md:h-[50vh]">
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-60 object-cover"
              />
              <div className="p-6">
                <strong>Address:</strong>
                <span className="text-base">{card.address}</span><br/>
                <strong>Tel:</strong>
                <span className="text-base">{card.phone}</span><br/>
                <strong>Website: </strong>
                <a
                  href={card.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-base text-blue-500 hover:underline"
                >
                  {card.website}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      
    </div>
  )
}

export default Attractions