import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";

   
  export default function SpSessionCard({authors,name,file,ind}) {
    return (
      <Card className="px-2 m-4 text-justify mx-auto text-white bg-[#343a40] pt-5 pb-10 h-auto">        
        <CardBody className="mb-8">
          <Typography variant="h5" className="mb-2 text-lg p-3 rounded-lg bg-blue-500">
            SS-{ind} : {name}
          </Typography>
          <p className="text-lg">Organisers</p>
          {authors.map((auth)=>{
            return <Typography variant="h5" color="blue-gray" className="mb-2 text-base">
            {auth}
          </Typography>
          })}
         
        </CardBody>
        <CardFooter className="absolute top-auto bottom-0 left-2 right-2 ">
          <a href={file} target="_blank" rel="noopener noreferrer"><Button className=" bg-blue-500 p-3 w-full my-4">Download</Button></a>
        </CardFooter>
      </Card>
    )};