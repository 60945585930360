import {
  Card,
  List,
  ListItem,
  Typography,
} from "@material-tailwind/react";
import React from "react";

const importantDates = [
  {
    heading: "Paper Submission Deadline",
    date: "20th September 2024",
  },
  {
    heading: "Acceptance Notification Due",
    date: "30th September 2024",
  },
  {
    heading: "Registration Due",
    date: "30th September 2024",
  },
  {
    heading: "Camera Ready Submission",
    date: "10th October 2024",
  },
  {
    heading: "Conference Dates",
    date: "17th - 18th January 2025",
  },
];

const Impdates = () => {
  return (
    <div className="w-80 mx-auto my-4">
      <h3 className="text-center font-bold text-xl text-white bg-blue-400 w-80">Important Dates</h3>
      <marquee direction="up" className="bg-gray-100 h-80">
        <Card className="flex-col bg-white">
          <List>
            {importantDates.map((item, index) => (
              <ListItem key={index}>
                <div>
                  <Typography variant="h6" color="blue-gray">
                    {item.heading}
                  </Typography>
                  <Typography
                    variant="small"
                    color="gray"
                    className="font-normal"
                  >
                    {item.date}
                  </Typography>
                </div>
              </ListItem>
            ))}
          </List>
        </Card>
      </marquee>
    </div>
  );
};

export default Impdates;
