import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='bg-gray-800 md:flex text-white text-left w-full justify-around
    p-8'>
    <div>
    <p className='font-medium text-red-500'>17-18th January 2025</p>

<p className='font-semibold text-md text-blue-100'>2nd International Conference on <br/> Computational Research and Data Analytics <br/>(ICCRDA-2025)</p>
<p className='font-medium text-red-500'>Duy Tan University, Da Nang,Vietnam</p>
    </div>
        <div>
          <h3 className='text-lg text-blue-100'>Links:</h3>
          <ul className='text-md font-light'>
            <li><Link to={'/'}>Home</Link></li>
            <li><Link to={'/registrations'}>Registrations</Link></li>
            <li><Link to={'/downloads'}>Downloads</Link></li>
            <li><Link to={'https://cmt3.research.microsoft.com/User/Login?ReturnUrl=%2FICCRDA2025'}>Paper Submission</Link></li>
          </ul>
        </div>
        <div>
          <h3 className='text-lg text-blue-100'>Contact us:</h3>
          <ul className='font-light text-md'>
            <li>Email: <a href='mailto:iccrda.congress@gmail.com'>iccrda.congress@gmail.com</a></li>
           
          </ul>
        </div>
        {/* <div>
          <h3>Social Media</h3>
          <div>
            <a href=''><img /></a>
          </div>
        </div> */}
    </div>
  )
}

export default Footer