import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import axios from "axios";

const CheckoutForm = () => {
  const [participantType, setParticipantType] = useState("Research Scholar/Student (Physical Mode)");
  const [price, setPrice] = useState("250");
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    // This effect runs when the component mounts and whenever the participantType changes
    const prices = {
      "Research Scholar/Student (Physical Mode)": 250,
      "Research Scholar/Student (Online Mode)": 150,
      "Standard Authors (Physical Mode)": 300,
      "Standard Authors (Online Mode)": 200,
      "Research Scholar/Student Participants": 100,
      "Standard Participants": 150,
    };

    setPrice(prices[participantType] || "");
  }, [participantType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(participantType)
      let country = "usd";
      await fetch(
        "https://extreme-ip-lookup.com/json/?key=raZUg7OdrMqIABCEZxQV"
      )
        .then((res) => res.json())
        .then((response) => {
          // console.log(response.country);
          country = response.country;
        })
        .catch((data, status) => {
          console.log("Request failed");
        });       
      if(!price || price===0){
        throw new Error('Price should be greater than $0')
      }
      const res = await axios.post(
        "https://iccrda-conf-dgzy.vercel.app/create-checkout-session",
        { participantType, price, country }
      );
      // console.log(res);
      // setResponseMessage(`Success: ${response.data.message}`)

      window.location.href = res.data.url;
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 py-6">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full sm:max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Participant Form
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="participantType"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Participant Type:
            </label>
            <select
              id="participantType"
              name="participantType"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={participantType}
              onChange={(e) => setParticipantType(e.target.value)}
            >
              <option value="Research Scholar/Student (Physical Mode)">
                Research Scholar/Student (Physical Mode)
              </option>
              <option value="Research Scholar/Student (Online Mode)">
                Research Scholar/Student (Online Mode)
              </option>
              <option value="Standard Authors (Physical Mode)">
                Standard Authors (Physical Mode)
              </option>
              <option value="Standard Authors (Online Mode)">
                Standard Authors (Online Mode)
              </option>
              <option value="Research Scholar/Student Participants">
                Research Scholar/Student Participants
              </option>
              <option value="Standard Participants">
                Standard Participants
              </option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="price"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Price:
            </label>
            <input
  type="text"
  id="price"
  name="price"
  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  value={`$${price}`}
  onChange={(e) => {
    const value = e.target.value;
    // Remove any non-numeric characters except for the dollar sign
    const numericValue = value.replace(/[^0-9.]/g, '');
    setPrice(numericValue);
  }}
  disabled={participantType !== 'Others'}
/>



            {/* </div> */}
          </div>
          <div className="mb-4">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
        {responseMessage && (
          <p className="mt-4 text-center">{responseMessage}</p>
        )}
      </div>
    </div>
  );
};

export default CheckoutForm;
