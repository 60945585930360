import React from 'react';
import technicalCommittee from '../constants/techList';

const TechComm = () => {
       
  return (
    <div>
        <h1 className='text-4xl font-semibold text-center m-3'>Technical Committee</h1>
        <hr className="mb-4 border-black border-2 w-96 m-auto"/>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4'>
        {technicalCommittee.map((m,ind)=>{
            return  <div key={m.ind} className="p-4 bg-[#3C5BBF] border border-gray-200 rounded-lg shadow-sm text-white">
          <h2 className="text-lg font-semibold mb-2">{m.name}</h2><hr/>
          <p >{m.affiliation}</p>
        </div>
        })}  
        </div>      
            
        
    </div>
  )
}

export default TechComm;