import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";
   
  export default function DownloadCard({file,name}) {
    return (
      <Card className="w-48 p-4 my-4 text-justify mx-auto">
        
        <CardBody>
          <Typography variant="h5" color="blue-gray" className="mb-2 h-16 text-center">
            {name}
          </Typography>
         
        </CardBody>
        <CardFooter className="pt-0">
          <Button className="bg-[#3C5BBF] p-3 w-full my-4"><a href={file} target="_blank">Download</a></Button>
        </CardFooter>
      </Card>
    )};