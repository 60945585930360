const steeringCommittee = [
        {
            category: "Patron",
            members: [
                {
                    name: "Prof. Payal Mago",
                    affiliation: "Director, Campus of Open Learning, University of Delhi"
                }
            ]
        },
        {
            category: "General Chair(s)",
            members: [
                
                {
                    name: "Prof. Joel Rodrigues",
                    affiliation: "Fortaleza Brazil and Instituto de Telecomunication, Portugal"
                },

                {
                    name: "Prof. Aboul Ella Hassanien",
                    affiliation: "Kuwait University, Kuwait"
                },
                {
                    name: "Prof. Nguyen Gia Nhu",
                    affiliation: "Duy Tan University, Vietnam"
                }
            ]
        },
        {
            category: "Honorary Chair(s)",
            members: [
                {
                    name: "Dr. Bao Le Nguyen",
                    affiliation: "Duy Tan University, Vietnam"
                },
                {
                    name: "Prof. Manu Malek",
                    affiliation: "EiC Computer and Electrical Engineering, Stevens Institute of Technology, USA"
                },
                {
                    name: "Jon G. Hall",
                    affiliation: "EiC Expert Systems (WILEY), The Open University, UK"
                }
            ]
        },
        {
            category: "Conference Chair(s)",
            members: [
                {
                    name: "Prof. Rajesh Ingle",
                    affiliation: "Vice-Chancellor, Symbiosis Skills and Professional University, India"
                },
                {
                    name: "Dr. Dang Viet Hung",
                    affiliation: "Duy Tan University, Vietnam"
                },
                {
                    name: "Prof. Gwanggil Jeon",
                    affiliation: "Incheon National University, Korea"
                },
                {
                    name: "Prof. Ahmed Taher Azar",
                    affiliation: "Prince Sultan University, Saudi Arabia"
                }
            ]
        },
        {
            category: "Technical Program Chair(s)",
            members: [
                {
                    name: "Prof. Giancarlo Fortino",
                    affiliation: "Università della Calabria, Italy"
                },
                {
                    name: "Dr. Imad Riza",
                    affiliation: "University of Applied Sciences for Technology Compiegne, France"
                },
                {
                    name: "Dr. Anand Nayyar",
                    affiliation: "Duy Tan University, Vietnam"
                },
                {
                    name: "Prof. Lalit Garg",
                    affiliation: "University of Malta, Malta"
                }
            ]
        },
        {
            category: "Publication Chair(s)",
            members: [
                {
                    name: "Dr. Vicente García Díaz",
                    affiliation: "University of Oviedo, Spain"
                },
                {
                    name: "Dr. Utku Kose",
                    affiliation: "Suleyman Demirel University, Isparta, Turkey"
                }
            ]
        },
        {
            category: "Publicity Chair(s)",
            members: [
                {
                    name: "Dr. Jafar A. Alzubi",
                    affiliation: "Al-Balqa Applied University, Salt Jordan"
                },
                {
                    name: "Dr. M. Tanveer",
                    affiliation: "Indian Institute of Technology, Indore"
                },
                {
                    name: "Dr. Deepak Gupta",
                    affiliation: "Maharaja Agrasen Institute of Technology, New Delhi India"
                }
            ]
        },
        {
            category: "Organizing Chair(s)",
            members: [
                {
                    name: "Dr. Suresh Chavan",
                    affiliation: "IIIT Raichur, India"
                },
                {
                    name: "Dr. Le Van Chung",
                    affiliation: "Duy Tan University, Vietnam"
                }
            ]
        },
        {
            category: "Convener(s)",
            members: [
                {
                    name: "Dr. Aditya Khamparia",
                    affiliation: "Babasaheb Bhimrao Ambedkar University, Lucknow, India"
                }
            ]
        }
    ];

export default steeringCommittee;