import React from 'react';
import logo from "../images/Logos/duytan.png";
import clg from "../images/bg/duytancampus.jpg";

const About = () => {
  return (
    <div className='p-4'>
    <img src={logo} className='mx-auto my-2'
    />
    <h1 className='text-4xl font-semibold text-center m-3'>About University</h1>
    <hr className="mb-4 border-black border-2 w-96 m-auto"/>
    <p className='text-lg font-normal'> 
    Duy Tan University is located in the heart of sunny Danang, on the Pacific Ocean. The University has steadily developed, sharing its ambition with the city to create a bustling, modern, international metropolis. Founded by the decree of the Prime Minister of Vietnam on the November 11th, 1994, Duy Tan University is the first and the largest private, multidisciplinary and diversified institution in Central Vietnam.
    </p>
    <img src={clg} className='m-auto rounded-lg my-4'/>
    <p className='text-lg font-normal'>
    Over twenty-seven years, Duy Tan University has made impressive progress and achieved many excellent accomplishments in education and research. The University has been named: - DTU ranks in the top 500 universities internationally in 2023 - Ranks in the top 100 in the Times Higher Education Asia University Rankings of 2022 - Ranks in the top 210 Asian universities in 2022 - Ranks in the top 801-1000 universities internationally in 2023, according to the QS Rankings - Accredited by ABET in Information Technology, and Electrical and Electronic Engineering - The first university in Vietnam to be certified by the United Nations Tourism Education Quality (UNWTO TedQual) in Hospitality - Ranks 251-300 worldwide in Computer Science; 251-300 in Engineering & Technology; 301-400 in Business & Economics; and 176-200 in Health & Medicine by Times Higher Education in 2023.
    </p>
    </div>
  )
}

export default About;