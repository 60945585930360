export const dataManagementTopics = [
    "Database administration and upgrading challenges",
    "Mining user logs",
    "Algorithms and Programming Techniques",
    "Data Exchange, Integration, quality, cleaning and Lineage",
    "Open Source: Challenges and opportunities",
    "Web-Based Learning: Innovation and Challenges",
    "Reputation analytics",
    "Agricultural Informatics and Communication",
    "Digital Photogrammetry, Remote Sensing, GIS and GPS",
    "Homecare analytics",
    "Population growth and migration analytics",
    "Community Information Systems",
    "Remote learning analytics",
    "User choice analytics, Branding analytics",
    "Utility proximity-search analytics",
    "e-governance, e-Commerce, e-business, e-Learning",
    "Irrigation Informatics",
    "Cyber-threats analytics",
    "Survey-based online asset analytics",
    "Business analytics",
    "Geology analytics, Global climate analytics",
    "Malware analytics",
    "Computational Economics",
    "Neuroinformatics",
    "Real-time data management in navigation and mobility",
    "Food-borne illness outbreaks analytics",
    "Online employment analytics",
    "Information Ecology and Knowledge Management",
    "Disaster Management"
  ];
  