import { Card, Typography } from "@material-tailwind/react";
 
const TABLE_HEAD = ["Category", "Early Registration (Before 23rd September 2024)", "Late Registration (After 23rd September 2024)"];
 

 
export default function RegTable({rows}) {
  return (
    <Card className="h-full w-full overflow-auto md:overflow-hidden my-4">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th key={head} className="border-b border-blue-gray-100  p-4 bg-[#343a40] ">
                <Typography                                 
                  className="leading-none font-bold  text-white text-md"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(({ Category, EarlyRegistration,LateRegistration}, index) => (
            <tr key={Category} className="odd:bg-[#dee2e6] even:bg-[#bee5eb]">
            <td className="p-4">
                <Typography  className="font-medium text-md">
                  {Category}
                </Typography>
              </td>
              <td className="p-4">
                <Typography  className="font-medium text-md">
                  {EarlyRegistration}
                </Typography>
              </td>
              <td className="p-4">
                <Typography  className="font-medium text-md">
                  {LateRegistration}
                </Typography>
              </td>              
              
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}