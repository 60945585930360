export const communicationNetworksTopics = [
    "Cloud communications and networking",
    "Optical Communication",
    "Satellite Communication",
    "Personal communication systems",
    "Mobile ad-hoc networks",
    "Virtual and overlay networks",
    "Future Internet Architecture and protocols",
    "Cyber Security",
    "Network Security",
    "Wireless communications",
    "Computer Security",
    "Digital Signature",
    "Wireless sensor networks",
    "Software defined networks",
    "Information theory and coding",
    "Telecommunication Services and Applications",
    "Quantum Networking",
    "Next Generation Networking",
    "Wearable network and systems",
    "Network Performance",
    "Vehicular networks and applications",
    "Qos Provisioning and Architectures"
  ];
  