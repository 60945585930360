import React from 'react';
import steeringCommittee from '../constants/steeringList';

const SteeringComm = () => {
    
  return (
    <div>
        <h1 className='text-4xl font-semibold text-center m-3'>Steering Committee</h1>
        <hr className="mb-4 border-black border-2 w-96 m-auto"/>
        {steeringCommittee.map((mem,ind)=>{
            return(<>
            <h1 key={ind} className='text-2xl font-medium mx-4 my-2'>{mem.category}</h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4'>
            {mem.members.map((m,ind)=>{
                return <div key={m.ind} className="p-4 bg-[#3C5BBF] border border-gray-200 rounded-lg shadow-sm text-white">
          <h2 className="text-lg font-semibold mb-2">{m.name}</h2><hr/>
          <p >{m.affiliation}</p>
          </div>
          })}
        </div>
        </>)
            })}
            
      </div>  
   
  )
}

export default SteeringComm