import React from 'react'

const Failed = () => {
  return (
    <div className="flex  justify-center bg-gray-100">
    <div className="bg-white shadow-lg rounded-lg p-6 sm:p-8 max-w-md text-center  m-12">
      
      <h2 className="text-2xl font-bold mb-4">Payment Failed!</h2>
      <p className="text-gray-600 mb-6">
        Unfortunately, your payment could not be processed. Please try again.
      </p>
      <button
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600"
        onClick={() => window.location.href = '/'}
      >
        Go to Homepage
      </button>
    </div>
  </div>
  )
}

export default Failed