// src/Carousel.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CardCarousel = () => {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,        
        lazyLoad: true,
        focusOnSelect: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,        
        centerPadding: '60px',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              centerMode: true,
              centerPadding: '0'
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
              centerMode: true,
              centerPadding: '0'
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '0'
            }
          }
        ]
      };
    
  const cards = [
    { id: 1, title: 'Card 1', content: 'This is card 1 content', image: '/images/attractions/img1.jpg' },
    { id: 2, title: 'Card 2', content: 'This is card 2 content', image: '/images/attractions/img2.jpg' },
    { id: 3, title: 'Card 3', content: 'This is card 3 content', image: '/images/attractions/img3.jpg' },
    { id: 4, title: 'Card 4', content: 'This is card 4 content', image: '/images/attractions/img4.jpg' },
    { id: 5, title: 'Card 5', content: 'This is card 5 content', image: '/images/attractions/img5.jpg' },
    { id: 6, title: 'Card 6', content: 'This is card 4 content', image: '/images/attractions/img6.jpg' },
    { id: 7, title: 'Card 7', content: 'This is card 5 content', image: '/images/attractions/img7.jpg' },
  ];


  return (
    <div className="w-full my-12">
    <Slider {...settings}>
      {cards.map((card, index) => (
        <div key={card.id} className="carousel-item p-0">
          <div className={`carousel-card ${index === 1 ? 'active' : ''} bg-white  shadow-lg overflow-hidden`}>
            <img src={card.image} alt={card.title} className="w-full h-72 md:h-80 object-cover"/>            
          </div>
        </div>
      ))}
    </Slider>
  </div>
  );
};



export default CardCarousel;
