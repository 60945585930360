import {
    Card,
    List,
    ListItem,
    ListItemPrefix,
    Typography,
  } from "@material-tailwind/react";
  import React from "react";
  import conftemp from "../Docs/conference-template-a4.docx"
  import conftempLetter from "../Docs/conference-template-letter.docx"
  import latexInst from "../Docs/IEEEtran_HOWTO.pdf";
  import latex from "../Docs/conference-latex-template_10-17-19.zip";
  import Elsevier from "../Docs/Elsevier SSRN_Word Template.docx";
  import bib from "../Docs/IEEEtranBST2.zip"
  
  const Downloads = () => {
    return (
      <div className="w-80 mx-auto my-4">
          <h3 className="text-center font-bold text-xl text-white bg-blue-400 w-80">Downloads</h3>
        <marquee direction="up" className="bg-gray-100 h-80">
          <Card className="flex-col bg-white">
            <List>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={conftemp}>Conference Template - A4</a>
                  </Typography>                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={conftempLetter}>US Letter</a>

                  </Typography>
                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={latex}>LaTeX Template</a>
                  
                  </Typography>
                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={Elsevier}>Elsevier SSRN Paper Template</a>
                  </Typography>
                  
                </div>
              </ListItem>
              
            </List>
          </Card>
        </marquee>
      </div>
    );
  };
  
  export default Downloads;
  