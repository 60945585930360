import React from "react";

const QualityPolicies = () => {
  return (
    <div className="p-4">
      <h1 className="text-4xl font-semibold text-center m-3">
        Quality Policies
      </h1>
      <hr className="mb-4 border-black border-2 md:w-96 m-auto" />
      <div className="md:px-8 pb-4">
      <div className="flex justify-around md:flex-row flex-col align-middle items-center">
      <strong className="md:w-[70%] w-full">By submitting papers to ICCRDA-2024, authors are agreeing to the following terms and conditions of the conference.</strong>
      <p className="md:w-[30%] w-full text-center bg-black text-white p-4 my-4">ICCRDA-2024 to follow Publication Ethics as laid out and followed by reputed international bodies/societies like <a href="https://publicationethics.org/" target="_blank" className="text-red-600 font-medium">COPE</a>, <a href="https://conferences.ieeeauthorcenter.ieee.org/author-ethics/" target="_blank" className="text-red-600 font-medium">IEEE</a> etc.</p>
      </div>
      <h4 className="text-red-500 text-lg font-semibold">DECLARATION BY THE AUTHORS</h4>
      <p>By submitting papers to ICCRDA-2024, authors are making the following declarations -</p>
      <ul className="list-disc text-base">
        <li>The work that has been submitted to ICCRDA-2024 is the original work carried out by the author(s) of the paper.</li>
        <li>All the authors have made significant contribution in the reported work, as well as in writing the paper [Applicable if there are more than one author in the paper].</li>
        <li>The submitter/corresponding author declares that he/she has obtained the consents of all the co-authors before including their names in the paper [Applicable if there are more than one author in the paper].</li>
        <li className="font-semibold">The paper is currently not under review for publication in any other conference or journal, and it will not be sent to any other conference or journal for consideration while it is under review in ICCRDA-2024.</li>
        <li>If the paper gets accepted after a review, one of the authors of the accepted paper will register and attend the conference and will make a presentation about work reported in the paper.</li>
        <li>I/we assure ICCRDA-2024 organizers that I/we will follow all the publication ethics of peer-reviewed conference.</li>
        <li>ICCRDA-2024 to follow Publication Ethics as laid out and followed by reputed international bodies/societies like <a href="https://publicationethics.org/" target="_blank" className="text-red-600 font-medium">COPE</a>, <a href="https://conferences.ieeeauthorcenter.ieee.org/author-ethics/" target="_blank" className="text-red-600 font-medium">IEEE</a> etc.</li>
      </ul>
    
      
      <h4 className="text-red-500 text-lg font-semibold mt-4">RIGHTS OF THE CONFERENCE ORGANIZER (CO)</h4>
      <ul className="list-disc">
        <li>Assignments of papers for review is sole discretion of the Conference Organizer (CO).</li>
        <li>Decision on acceptance and rejection of the papers will solely be taken by the CO based on reviewers’ comments.</li>
        <li>CO has full authority on preparing technical program of the conference and on scheduling papers for oral presentations.</li>
        <li>CO reserves the right to publish details of accepted papers (as well as that of authors) on the conference website and other related forums maintained by the CO.</li>
        <li>CO to have full authority regarding preponing/postponing dates of any event associated with the conference including but not limited to paper submission date, results announcement date, conference dates, proceeding publication date etc.</li>
        <li>Even after the acceptance and presentation of the papers in the conference, if some camera ready papers do not meet the quality standards in terms of technical content, English language, presentation and formatting of manuscripts as per the guidelines, then CO reserves the right not to include such papers in the proceedings.</li>
        <li>Photographs and videos taken during the conference will be freely used by the CO in multiple forums. By participating in the conference, authors/delegates by default give their consents to the CO to use their photographs and videos taken during the conference.</li>
      </ul>
      <h4 className="text-red-500 text-lg font-semibold mt-4">FINANCIAL OBLIGATIONS</h4>
      <ul className="list-disc">
        <li>CO is not liable to refund the registration fee of the no-show papers.</li>
        <li>If any accepted paper is removed from the proceedings due to quality issues, then also CO is not liable to refund the fee.</li>
      </ul>
      </div>
      </div>
  );
};

export default QualityPolicies;
