export const computationalIntelligenceTopics = [
    "Quantum inspired computational intelligence",
    "Hybrid Computational Intelligence",
    "Swarm Intelligence",
    "Rough set theoretic approaches",
    "Parallel and Grid Computing",
    "Distributed Computing",
    "Deep Learning",
    "Soft Computing",
    "Decision Support and Decision making",
    "Artificial Intelligence",
    "Biomedical Computing",
    "Fuzzy sets and vague sets",
    "Computer Vision",
    "Machine Learning",
    "Cloud Computing",
    "High Performance Computing",
    "Big Data persistence and preservation",
    "Evolutionary algorithms"
  ];
  