import React from 'react'



const Success = () => {
    return (
        <div className="flex items-center justify-center bg-gray-100">
        <div className=" bg-white shadow-lg rounded-lg p-4 sm:p-8 max-w-md text-center m-12">        
          <h2 className="text-2xl font-bold mb-4">Payment Successful!</h2>
          <p className="text-gray-600 mb-6">
            Thank you for your payment. Your registration has been successfully completed.
          </p>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600"
            onClick={() => window.location.href = '/'}
          >
            Go to Homepage
          </button>
        </div>
      </div>
      );
}

export default Success