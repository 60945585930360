import React from 'react';
import DownloadCard from '../Utilities/DownloadCard';
import conftemp from "../Docs/conference-template-a4.docx"
import conftempLetter from "../Docs/conference-template-letter.docx"
import latexInst from "../Docs/IEEEtran_HOWTO.pdf";
import latex from "../Docs/conference-latex-template_10-17-19.zip";
import Elsevier from "../Docs/Elsevier SSRN_Word Template.docx";
import bib from "../Docs/IEEEtranBST2.zip"
import spsess from "../Docs/Special_Session_ICCRDA.docx"

const Downloads = () => {
  return (
    <div>
        <h1 className='text-4xl font-semibold text-center m-3'>Downloads</h1>
        <hr className="mb-4 border-black border-2 w-96 m-auto"/>
        <div className='bg-gray-300 w-2/3 m-auto p-4 my-4 grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>           
           <DownloadCard name="Conference Template - A4" file={conftemp}/>      
           <DownloadCard name="US Letter" file={conftempLetter}/>      
           <DownloadCard name="LaTeX Template Instructions" file={latexInst}/>     
           <DownloadCard name="LaTeX Template" file={latex}/>      
           <DownloadCard name="LaTeX Bibliography Files" file={bib}/>      
           <DownloadCard name="Elsevier SSRN Paper Template" file={Elsevier}/>      
           <DownloadCard name="Special Session Proposal Template" file={spsess}/>  
        </div>
    </div>
  )
}

export default Downloads